import { INVESTOR_STRATEGY_LIST_ITEM_NO_STRATEGY_LABEL } from '@app/features/investor-strategy/investor-strategy-contants'
import { useInvestor, useInvestorStrategy } from '@gain/api/app/hooks'
import { BriefcaseMoneyIcon, ChevronRightIcon } from '@gain/components/icons'
import { SlotHome, SlotName } from '@gain/components/slot'
import Head from '@gain/modules/head'
import { useIsXs } from '@gain/utils/responsive'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import React, { Suspense } from 'react'
import { generatePath, Redirect, Route, Switch, useHistory } from 'react-router-dom'

import Loading from '../../common/loading'
import ProfileTabBar, { ProfileTabContainer, useActiveTab } from '../../common/profile-tab-bar'
import MobilePageHeader from '../../layout/mobile-page-header'
import { generateInvestorPagePath, INVESTOR_PAGE_SUMMARY_PATH } from '../utils'
import RouteAnalysis from './route-analysis'
import RouteBenchmarking from './route-benchmarking'
import RouteCompanies from './route-companies'
import RouteFunds from './route-funds'
import { useStrategyTabs } from './route-strategy-hooks'
import {
  INVESTOR_STRATEGY_ANALYSIS_PAGE_PATH,
  INVESTOR_STRATEGY_ASSETS_PAGE_PATH,
  INVESTOR_STRATEGY_BENCHMARKING_PAGE_PATH,
  INVESTOR_STRATEGY_FUNDS_PAGE_PATH,
  useInvestorStrategyPageParams,
} from './route-strategy-path'

const StyledInvestorNameTypography = styled(Typography)(({ theme }) => ({
  transition: theme.transitions.create(['color'], {
    duration: theme.transitions.duration.shortest,
    easing: theme.transitions.easing.easeInOut,
  }),
  willChange: 'color',

  '&:hover': {
    color: theme.palette.text.primary,
  },
}))

const StyledProfileTabContainer = styled(ProfileTabContainer)({
  flex: 1,
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
})

export default function RouteInvestorStrategy() {
  const params = useInvestorStrategyPageParams()
  const history = useHistory()
  const isXs = useIsXs()

  const tabs = useStrategyTabs()
  const activeTab = useActiveTab(tabs)

  const swrInvestor = useInvestor({
    id: params.investorId,
    preview: params.preview,
  })

  // TODO:: Food for thought: add the investor through populate to the strategy?
  const swrInvestorStrategy = useInvestorStrategy({
    id: params.strategyId,
  })

  if (!swrInvestor.data || !swrInvestorStrategy.data) {
    return null
  }

  const title = [
    swrInvestor.data.name || '',
    swrInvestorStrategy.data.name ||
      params.strategyName ||
      INVESTOR_STRATEGY_LIST_ITEM_NO_STRATEGY_LABEL,
  ].join(' - ')

  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>
      {isXs && <MobilePageHeader title={title} />}

      <ProfileTabBar
        activeTab={activeTab}
        defaultIcon={BriefcaseMoneyIcon}
        logoFileUrl={swrInvestor.data.logoFileUrl}
        onLogoClick={() => {
          history.push(generateInvestorPagePath(params, INVESTOR_PAGE_SUMMARY_PATH))
        }}
        subtitleUrl={swrInvestor.data.url}
        tabActions={<SlotHome slotName={SlotName.Tabs} />}
        tabs={tabs}
        title={
          <Stack
            alignItems={'center'}
            direction={'row'}>
            <StyledInvestorNameTypography
              color={'text.secondary'}
              onClick={(event) => {
                event.stopPropagation()

                history.push(generateInvestorPagePath(params, INVESTOR_PAGE_SUMMARY_PATH))
              }}
              variant={'inherit'}>
              {swrInvestor.data.name || params.investorName}
            </StyledInvestorNameTypography>
            &nbsp;
            <ChevronRightIcon fontSize={'inherit'} />
            &nbsp;
            <Typography variant={'inherit'}>{swrInvestorStrategy.data.name}</Typography>
          </Stack>
        }
        disableIndicatorAnimation
        replaceUrl
        sticky
      />

      <StyledProfileTabContainer
        maxWidth={'lg'}
        disableGuttersTop>
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route
              path={INVESTOR_STRATEGY_ASSETS_PAGE_PATH}
              exact>
              <RouteCompanies investor={swrInvestor.data} />
            </Route>
            <Route
              path={INVESTOR_STRATEGY_FUNDS_PAGE_PATH}
              exact>
              <RouteFunds investor={swrInvestor.data} />
            </Route>
            <Route
              path={INVESTOR_STRATEGY_ANALYSIS_PAGE_PATH}
              exact>
              <RouteAnalysis investor={swrInvestor.data} />
            </Route>
            <Route
              path={INVESTOR_STRATEGY_BENCHMARKING_PAGE_PATH}
              exact>
              <RouteBenchmarking investor={swrInvestor.data} />
            </Route>
            <Route path={'*'}>
              <Redirect to={generatePath(INVESTOR_STRATEGY_ASSETS_PAGE_PATH, params)} />
            </Route>
          </Switch>
        </Suspense>
      </StyledProfileTabContainer>
    </>
  )
}
