import { useAssetList } from '@gain/api/app/hooks'
import { AssetListItem } from '@gain/rpc/app-model'
import { ListFilter, ListSort } from '@gain/rpc/list-model'
import { Option } from '@gain/rpc/shared-model'
import { listSort } from '@gain/rpc/utils'
import Grid from '@mui/material/Grid'
import React, { useMemo, useState } from 'react'

import Card, { CardHeader } from '../../common/card/card'
import Loading from '../../common/loading'
import SelectMenu from '../../common/select-menu/select-menu'
import { useTrackEvent } from '../google-analytics'
import AssetValuationTable, { ValuationPeriod } from './asset-valuation-table'

interface ValuationPageProps {
  filter: ListFilter<AssetListItem>[]
  limit?: number
  sort?: ListSort<AssetListItem>[]
  similarToAssetName?: string
  listedAssetsCardTitle: string
}

export default function ValuationPage({
  filter,
  limit = 150,
  sort = [listSort('enterpriseValueEur', 'desc')],
  similarToAssetName,
  listedAssetsCardTitle,
}: ValuationPageProps) {
  const trackEvent = useTrackEvent()
  const swrAssets = useAssetList({
    filter,
    limit,
    sort,
  })
  const [valuationPeriod, setValuationPeriod] = useState<ValuationPeriod>('LastFiscalYear')
  const valuationPeriods = useMemo(() => {
    const date = new Date()
    const year = date.getFullYear()

    return new Array<Option<ValuationPeriod>>(
      { label: `Last year (${year - 1})`, value: 'LastFiscalYear' },
      { label: 'LTM', value: 'LastTwelveMonths' },
      { label: `Current year (${year}E)`, value: 'CurrentFiscalYear' },
      { label: 'NTM', value: 'NextTwelveMonths' },
      { label: `Next year (${year + 1}E)`, value: 'NextFiscalYear' }
    )
  }, [])

  if (swrAssets.loading) {
    return <Loading />
  }

  return (
    <Grid
      spacing={2}
      container>
      <Grid
        xs={12}
        item>
        <Card>
          <CardHeader
            actions={
              <SelectMenu
                label={'Valuation period'}
                onChange={(value) => {
                  setValuationPeriod(value)
                  trackEvent('Set valuation period', 'Valuation table', value)
                }}
                options={valuationPeriods}
                sx={{ mr: -0.5 }}
                value={valuationPeriod}
              />
            }
            title={listedAssetsCardTitle}
          />
          <AssetValuationTable
            assets={swrAssets.data.items}
            initialSort={sort}
            period={valuationPeriod}
            similarToAssetName={similarToAssetName}
          />
        </Card>
      </Grid>
    </Grid>
  )
}
