import {
  AssetListItem,
  IndustryMarketSegmentListItem,
  InvestorProfileStrategy,
} from '@gain/rpc/app-model'
import { useElementWidthEffect } from '@gain/utils/dom'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import * as echarts from 'echarts/core'
import React, {
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useReducer,
  useRef,
  useState,
} from 'react'

import { chartColorSet } from '../../../common/chart/chart-colors'
import {
  ChartGroup,
  ChartGroupByConfig,
  ChartGroupBySelect,
  ChartGroupValueType,
  getPreferredChartGroupBy,
  useChartGroups,
} from '../../../common/chart/chart-groups'
import ChartLegend from '../../../common/chart/chart-legend'
import { ChartSizeTypeConfig, ChartSizeTypeSelect } from '../../../common/chart/chart-select'
import { DropdownMenuOptions } from '../../../common/dropdown-menu'
import EChart, { ECOption } from '../../../common/echart/echart'
import { AssetChartSizeType, useAssetChartSizeTypes } from '../chart-utils/asset-chart-size-types'
import { AssetChartGroupType } from '../chart-utils/asset-group-by-configs'
import useGroupByOptions from '../chart-utils/use-group-by-options'
import { Axis, AxisConfig, AxisConfigId, findAxisConfigById } from './axis/axis-config'
import AxisDropdownMenu from './axis/axis-dropdown-menu'
import { createBubbles } from './chart-bubble'
import {
  changeGroupBy,
  changeGroups,
  changeVisibleGroups,
  chartReducer,
  initialState,
  mouseEnterGroup,
  mouseLeaveGroup,
  updateBubbles,
} from './chart-reducer'
import CompanyBubbleEChartTooltip from './tooltip'
import useActiveBubble from './use-active-bubble'
import useCompanyBubbleEChartOption from './use-company-bubble-echart-option'

const StyledChartContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 3, 1, 3),
}))

const StyledEChartContainer = styled('div')(({ theme }) => ({
  position: 'relative', // Required for the tooltip to work
  margin: theme.spacing(1, 0),
}))

export interface CompanyBubbleEChartProps {
  axisConfig: DropdownMenuOptions<AxisConfig>
  assets: AssetListItem[]
  height: number
  industrySegments?: IndustryMarketSegmentListItem[]
  investorStrategies?: InvestorProfileStrategy[]
  defaultGroupTypeId?: AssetChartGroupType
  defaultSizeTypeId?: AssetChartSizeType
  visibleGroupId?: ChartGroupValueType
  xAxisId: AxisConfigId
  yAxisId: AxisConfigId
  onChangeSettings?: (
    xAxisConfig: AxisConfig,
    yAxisConfig: AxisConfig,
    sizeType: ChartSizeTypeConfig<AssetListItem>
  ) => void
}

/**
 * This component shows a bubble chart using ECharts. We're currently in the
 * middle of switching from D3 to ECharts for our charts, so we should probably
 * make this component more generic and reusable when new bubble charts are added.
 */
function CompanyBubbleEChart({
  axisConfig,
  assets,
  height,
  industrySegments,
  visibleGroupId,
  investorStrategies,
  defaultGroupTypeId,
  defaultSizeTypeId,
  xAxisId,
  yAxisId,
  onChangeSettings,
}: CompanyBubbleEChartProps) {
  const groupByOptions = useGroupByOptions(industrySegments, investorStrategies, assets)
  const eChartsInstanceRef = useRef<echarts.ECharts | undefined>()
  const assetSizeTypes = useAssetChartSizeTypes()
  const [chartState, dispatch] = useReducer(chartReducer, initialState)
  const [xAxisConfig, setXAxisConfig] = useState<AxisConfig | null>(
    findAxisConfigById(xAxisId, axisConfig)
  )
  const [yAxisConfig, setYAxisConfig] = useState<AxisConfig | null>(
    findAxisConfigById(yAxisId, axisConfig)
  )

  useEffect(() => {
    setXAxisConfig(findAxisConfigById(xAxisId, axisConfig))
  }, [xAxisId, axisConfig])

  useEffect(() => {
    setYAxisConfig(findAxisConfigById(yAxisId, axisConfig))
  }, [yAxisId, axisConfig])

  // TODO: benchmarking cleanup: this is a temporary solution to handle both the
  //   internal and external change of the axes. For the benchhmarking page we
  //   need to control this externally, while other pages only set a default axis.
  //   We will remove this before final release of the feature by removing all
  //   non-benchmarking instances.
  const handleAxisChange = useCallback(
    (axis: Axis) => (newConfig: AxisConfig) => {
      if (axis === Axis.X) {
        setXAxisConfig(newConfig)
      } else {
        setYAxisConfig(newConfig)
      }
    },
    []
  )

  // This indicates a programmer error as the default X and Y-axes are hardcoded
  if (!xAxisConfig || !yAxisConfig) {
    throw new Error('Invalid axis configuration for x or y axis in CompanyBubbleEChart')
  }

  // Determine the size type of the bubbles
  const [sizeType, setSizeType] = useState<ChartSizeTypeConfig<AssetListItem>>(
    assetSizeTypes.find(({ id }) => id === defaultSizeTypeId) || assetSizeTypes[0]
  )

  // Keep track of the currently active group
  const [activeGroupBy, setActiveGroupBy] = useState<ChartGroupByConfig<AssetListItem>>(
    getPreferredChartGroupBy<AssetListItem>(groupByOptions, defaultGroupTypeId)
  )

  // Extract the groups from the assets based on the active groupBy function
  const groups = useChartGroups(assets, activeGroupBy)

  // If groupByOptions change, we have to update the activeGroupBy because it references
  // an option that is no longer available (object reference changed)
  useLayoutEffect(() => {
    const groupByOption = groupByOptions.find((option) => option.id === activeGroupBy.id)
    if (groupByOption) {
      setActiveGroupBy(groupByOption)
    } else {
      setActiveGroupBy(getPreferredChartGroupBy(groupByOptions, defaultGroupTypeId))
    }
  }, [groupByOptions, defaultGroupTypeId, activeGroupBy])

  // If the groups change, we reset everything
  useEffect(() => {
    dispatch(changeGroups(groups, visibleGroupId))
  }, [groups, visibleGroupId])

  // Update chart state if activeGroupBy changes
  useEffect(() => {
    dispatch(changeGroupBy(assets, chartState.visibleGroups, activeGroupBy))
  }, [assets, activeGroupBy, chartState.visibleGroups])

  // Returns group color for use in the graph option
  const getColor = useCallback(
    (asset: AssetListItem) =>
      chartState.availableGroups.find((group) => group.value === activeGroupBy.getValue(asset))
        ?.color || chartColorSet[0],
    [activeGroupBy, chartState.availableGroups]
  )

  // Returns the size of the bubble
  const getSize = useCallback((asset: AssetListItem) => sizeType.getValue(asset), [sizeType])

  // Create "option"-config for the chart
  const [option, setOption] = useState<ECOption | null>(null)
  const newOption = useCompanyBubbleEChartOption(
    assets,
    chartState.bubbles,
    getColor,
    chartState.allVisible,
    chartState.highlightedAssets,
    xAxisConfig,
    yAxisConfig
  )
  useEffect(() => {
    setOption(newOption)
  }, [newOption])

  // Keep the ECharts reference up-to-date each time it (re-)initializes
  const handleInit = useCallback((eChartsInstance: echarts.ECharts) => {
    eChartsInstanceRef.current = eChartsInstance
  }, [])

  // Resize ECharts whenever the card size changes
  const containerRef = useRef<HTMLDivElement>(null)
  const resizeECharts = useCallback(() => eChartsInstanceRef.current?.resize(), [])
  useElementWidthEffect(containerRef, resizeECharts)

  // The tooltip is handled outside of ECharts to allow for more customization.
  // We track the currently active bubble by listening to mouse events on the chart.
  const [activeBubble, handleMouseMove, handleMouseOut, handleClick] = useActiveBubble(assets)

  // Generate the bubbles for the chart
  useEffect(() => {
    const bubbles = createBubbles(
      chartState.visibleAssets,
      assets,
      getSize,
      xAxisConfig,
      yAxisConfig
    )
    dispatch(updateBubbles(bubbles))
  }, [assets, chartState.allVisible, chartState.visibleAssets, getSize, xAxisConfig, yAxisConfig])

  // Removes groups from the ChartLegend that have zero bubbles due to missing
  // x, y or size values.
  const filterGroupsWithData = (allGroups: ChartGroup<AssetListItem>[]) => {
    return allGroups.filter((group) =>
      group.items.some((item) => chartState.bubbles.some((bubble) => bubble.assetId === item.id))
    )
  }

  // Notify the parent component when any of the axes or size type changes
  useEffect(() => {
    onChangeSettings?.(xAxisConfig, yAxisConfig, sizeType)
  }, [onChangeSettings, sizeType, xAxisConfig, yAxisConfig])

  return (
    <StyledChartContainer
      ref={containerRef}
      sx={{ pb: 0 }}>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        sx={{ pb: 1 }}>
        <AxisDropdownMenu
          activeAxis={yAxisConfig}
          axis={Axis.Y}
          axisConfig={axisConfig}
          onSelect={handleAxisChange(Axis.Y)}
        />

        {groupByOptions.length > 0 && (
          <Stack
            direction={'row'}
            gap={2}>
            <ChartSizeTypeSelect
              onChange={setSizeType}
              options={assetSizeTypes}
              value={sizeType}
            />
            <ChartGroupBySelect
              onChange={setActiveGroupBy}
              options={groupByOptions}
              value={activeGroupBy}
            />
          </Stack>
        )}
      </Stack>

      <StyledEChartContainer>
        {activeBubble && (
          <CompanyBubbleEChartTooltip
            activeBubble={activeBubble}
            assets={assets}
            sizeConfig={sizeType}
            xAxisConfig={xAxisConfig}
            yAxisConfig={yAxisConfig}
          />
        )}

        {option && (
          <EChart
            onClick={handleClick}
            onInit={handleInit}
            onMouseMove={handleMouseMove}
            onMouseOut={handleMouseOut}
            option={option}
            style={{ height }}
          />
        )}
      </StyledEChartContainer>

      <AxisDropdownMenu
        activeAxis={xAxisConfig}
        axis={Axis.X}
        axisConfig={axisConfig}
        onSelect={handleAxisChange(Axis.X)}
      />

      <Divider sx={{ mx: -3 }} />

      <ChartLegend
        groups={filterGroupsWithData(chartState.availableGroups)}
        onChange={(newVisibleGroups) => dispatch(changeVisibleGroups(newVisibleGroups))}
        onGroupMouseEnter={(group) => dispatch(mouseEnterGroup(group))}
        onGroupMouseLeave={() => dispatch(mouseLeaveGroup())}
        value={filterGroupsWithData(chartState.visibleGroups)}
      />
    </StyledChartContainer>
  )
}

export default memo(CompanyBubbleEChart)
