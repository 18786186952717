import { useBookmarkListAddAssets } from '@gain/api/app/hooks'
import { XIcon } from '@gain/components/icons'
import { AssetListItem, CustomAssetList } from '@gain/rpc/app-model'
import { ListFilter } from '@gain/rpc/list-model'
import { ColumnVisibilityModel, useVisibleColumns } from '@gain/utils/table'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Slide from '@mui/material/Slide'
import Stack from '@mui/material/Stack'
import { alpha, styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { isEqual } from 'lodash'
import React, { useCallback, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { usePrevious } from 'react-use'

import Card, { CardHeader } from '../../../common/card/card'
import Table from '../../../common/table'
import { generateAssetPagePath } from '../../../routes/utils'
import {
  REGION_FILTER_MAP,
  REGION_FILTER_MENU,
  REGION_FILTERS,
  RegionFilterField,
  RegionFilterItem,
} from '../../asset/asset-filter-bar/asset-filter-region-only-config'
import { ExportListButton } from '../../export/export-button'
import FilterBar, { FilterModel } from '../../filter/filter-bar'
import { useListViewRowSelection } from '../../list-view'
import { columnVisibility, useSimilarCompaniesSelectColumns } from './similar-companies-card.hooks'
import { SimilarCompaniesSubmitButton } from './similar-companies-submit-button'

const StyledFilterBarContainer = styled('div')(({ theme }) => ({
  paddingLeft: theme.spacing(3),
  marginBottom: theme.spacing(-1),
}))

const StyledInnerBar = styled('div')(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.text.primary,
  borderRadius: 16,
  padding: theme.spacing(1),
  justifyContent: 'space-between',
  display: 'flex',
  alignItems: 'center',
}))

const StyledCrossIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
  marginRight: theme.spacing(1),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.4),
  },
}))

const selectCompaniesColumnVisibility: ColumnVisibilityModel<AssetListItem> = {
  id: 600,
  ...columnVisibility,
}

export interface SimilarCompaniesSelectProps {
  bookmarkList: CustomAssetList
  assetListItems?: AssetListItem[]
  listFilterParams: ListFilter<AssetListItem>[]
  filterModel?: FilterModel<RegionFilterItem, RegionFilterField>
  setFilterModel?: (value: FilterModel<RegionFilterItem, RegionFilterField>) => void
  updateCachedList: () => void
}

export function SimilarCompaniesSelect({
  bookmarkList,
  assetListItems = [],
  listFilterParams,
  filterModel,
  setFilterModel,
  updateCachedList,
}: SimilarCompaniesSelectProps) {
  const tableRef = useRef<HTMLDivElement>(null)
  const history = useHistory()

  const [handleSelectionChange, selectedRows, selectedRowIndexes, clearSelection] =
    useListViewRowSelection(assetListItems)

  const columns = useSimilarCompaniesSelectColumns(
    assetListItems?.length,
    handleSelectionChange,
    selectedRowIndexes
  )

  const prev = usePrevious(assetListItems)

  useEffect(() => {
    if (!isEqual(prev, assetListItems)) {
      clearSelection()
    }
  }, [assetListItems, clearSelection, prev])

  const visibleColumns = useVisibleColumns(tableRef, columns, selectCompaniesColumnVisibility)

  const addAssets = useBookmarkListAddAssets()

  const handleRenderBulkActions = useCallback(() => {
    return (
      <Slide
        direction={'up'}
        in={selectedRowIndexes.length > 0}>
        <Box
          padding={1}
          position={'sticky'}
          top={'calc(100vh - 5rem)'}
          width={'100%'}
          zIndex={3}>
          <StyledInnerBar>
            <Box
              alignItems={'center'}
              display={'flex'}>
              <StyledCrossIconButton
                onClick={clearSelection}
                size={'small'}>
                <XIcon />
              </StyledCrossIconButton>
              <Typography variant={'body2'}>
                {selectedRowIndexes.length}{' '}
                {selectedRowIndexes.length === 1 ? 'company' : 'companies'} selected
              </Typography>
            </Box>
            <SimilarCompaniesSubmitButton
              callback={async () => {
                await addAssets(
                  bookmarkList,
                  selectedRows.map((asset) => asset.id),
                  false
                )
                updateCachedList()
              }}
              reset={!!(prev && assetListItems && selectedRows.length === 0)}
            />
          </StyledInnerBar>
        </Box>
      </Slide>
    )
  }, [
    addAssets,
    assetListItems,
    bookmarkList,
    clearSelection,
    prev,
    selectedRowIndexes.length,
    selectedRows,
    updateCachedList,
  ])

  return (
    <>
      {handleRenderBulkActions()}
      <Card sx={{ minWidth: '100%', flex: 1, marginTop: '-4rem', marginBottom: '4rem' }}>
        <CardHeader
          actions={
            assetListItems.length !== 0 && (
              <ExportListButton
                method={'data.exportAssets'}
                params={{
                  filter: listFilterParams,
                  sort: ['-matchingTagsCount'],
                  filename: `${bookmarkList.title} - Similar companies.xlsx`,
                  columns: [],
                  search: '',
                }}
                tooltipMode={'always'}
                variant={'text'}
              />
            )
          }
          title={
            <Stack
              alignItems={'center'}
              direction={'row'}
              flexWrap={'wrap'}
              gap={1}>
              <Typography
                color={'text.primary'}
                variant={'h5'}
                noWrap>
                Similar and adjacent companies
              </Typography>
            </Stack>
          }
        />

        {setFilterModel && (
          <StyledFilterBarContainer>
            <FilterBar
              addFilterMenu={REGION_FILTER_MENU}
              defaultFilterModel={REGION_FILTERS}
              filterConfigMap={REGION_FILTER_MAP}
              gaCategory={'Asset'}
              initialFilterModel={filterModel || undefined}
              onFilterChange={setFilterModel}
              disableAddFilter
              disableClear
              disableDeleteFilter
              disableOrFilter
            />
          </StyledFilterBarContainer>
        )}

        <Table
          ref={tableRef}
          amountOfPlaceholders={10}
          columns={visibleColumns}
          onRowClick={(row) => history.push(generateAssetPagePath({ id: row.id, name: row.name }))}
          rows={assetListItems}
        />
      </Card>
    </>
  )
}
